<template>
	<b-row>
		<b-col cols="12" md="8">
			<b-card title="Campaign Create">
				<validation-observer ref="campaignForm" tag="form"
					@submit.prevent="validationFormControl().then(formSubmitted)">
					<b-row class="align-items-center">
						<b-col md="12">
							<b-form-group label="Appstore Link" label-for="link">
								<validation-provider #default="{ errors }" name="Appstore Link" rules="required">
									<b-row>
										<b-col md="10" class="mb-1">
											<b-form-input :state="errors.length > 0 ? false:null"
												placeholder="Appstore Link" @change="reviewOk = null"
												v-model="campaignData.app_id" />
										</b-col>
										<b-col md="2">
											<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'"
												variant="outline-secondary" class="w-100 previewButton"
												@click="previewAppstore(campaignData.app_id)">
												<feather-icon icon="EyeIcon" class="mr-50" />
												<span>Preview</span>
											</b-button>
										</b-col>
									</b-row>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="12">
							<validation-provider #default="{ errors }" name="Appstore" rules="required">
								<b-form-input hidden :state="errors.length > 0 ? false:null" v-model="reviewOk" />
								<small class="text-danger" v-if=" errors[0]">Please confirm by clicking the preview
									application button.</small>

							</validation-provider>
						</b-col>

						<b-col md="12" class="my-45">
							<hr />
						</b-col>
						<b-col md="6">
							<validation-provider #default="{ errors }" name="Campaign Type" rules="required">
								<b-form-group label="Campaign Type" label-for="Campaign Type"
									:state="errors.length > 0 ? false:null">
									<v-select :options="['Snapchat']" value="Snapchat" placeholder="Campaign Type"
										class="w-100" v-model="campaignData.type">
									</v-select>


								</b-form-group>
							</validation-provider>
						</b-col>
						<b-col md="6">
							<b-form-group label="Campain Name" label-for="name">
								<validation-provider #default="{ errors }" name="Campaign Name" rules="required">
									<b-form-input :state="errors.length > 0 ? false:null" placeholder="Campaign Name"
										v-model="campaignData.name" />
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="12" class="mt-50">
							<b-form-group label="Video Select (1080x1920 - H264 .mp4 - .mov)" label-for="link">
								<validation-provider #default="{ errors }" name="File" rules="required">

									<b-form-file v-model="campaignData.file" :state="errors.length > 0 ? false:null"
										ref="file" @change="handleFileObject($event)" accept=".mp4, .mov">
										<template slot="file-name" slot-scope="{ names }">
											<b-badge variant="primary">
												{{ names[0] }}
											</b-badge>
										</template>
									</b-form-file>
								</validation-provider>
							</b-form-group>


						</b-col>

						<b-col md="12">
							<hr />
						</b-col>

						<b-col md="6">
							<validation-provider #default="{ errors }" name="Organization" rules="required"
								v-if="organizationOptions">
								<b-form-group label="Organization" label-for="Organization"
									:state="errors.length > 0 ? false:null">
									<v-select :options="organizationOptions" @input="organizationAdAccounts($event)"
										:reduce="(option) => option.value" placeholder="Select Organization"
										class="w-100" v-model="campaignData.organization_id">
									</v-select>
								</b-form-group>
							</validation-provider>
						</b-col>

						<b-col md="6">
							<validation-provider #default="{ errors }" name="Ad Account" rules="required"
								v-if="addAccountsOptions">
								<b-form-group label="Ad Account" label-for="Ad Account"
									:state="errors.length > 0 ? false:null">
									<v-select :options="addAccountsOptions" :reduce="(option) => option.value"
										placeholder="Select Ad Account" class="w-100"
										v-model="campaignData.ad_account_id">
									</v-select>
								</b-form-group>
							</validation-provider>
							<validation-provider #default="{ errors }" name="Ad Account" rules="required"
								v-if="!addAccountsOptions">
								<b-form-group label="Ad Account" label-for="Ad Account"
									:state="errors.length > 0 ? false:null">
									<v-select placeholder="Please Select Organization" class="w-100" disabled>
									</v-select>
								</b-form-group>
							</validation-provider>
						</b-col>

						<b-col md="12">
							<b-form-group label="Headline" label-for="name">
								<validation-provider #default="{ errors }" name="Headline" rules="required">
									<b-form-input :state="errors.length > 0 ? false:null" placeholder="Headline"
										v-model="campaignData.headline" />
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group label="Start Date" label-for="startDate">
								<validation-provider #default="{ errors }" name="startDate" rules="required">
									<flat-pickr :state="errors.length > 0 ? false:null" @input="changeEndTime($event)"
										v-model="campaignData.start_time" class="form-control"
										placeholder="Select Start Date"
										:config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}" />
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group label="End Date" label-for="endDate">
								<validation-provider #default="{ errors }" name="endDate" rules="required">
									<flat-pickr :state="errors.length > 0 ? false:null"
										v-model="campaignData.end_time" class="form-control"
										placeholder="Select End Date"
										:config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}" />
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="12">
							<hr />
						</b-col>

					</b-row>

					<b-row>
						<b-col cols="12" class="text-right" v-if="!uploadLoad">
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
								Submit
							</b-button>
						</b-col>
						<b-col cols="12" v-if="uploadLoad">
							<b-progress max="100" height="2rem" animated class="progress-bar-success">
								<b-progress-bar :value="uploadProgress" :label="`Uploading: ${uploadProgress}%`" variant="success" />
							</b-progress>
						</b-col>
					</b-row>

				</validation-observer>
			</b-card>
		</b-col>

		<b-col cols="12" md="4">
			<b-card title="App Preview Demo" no-body>
				<div class="guru-browser" id="blanik_me-macwindow-1">
					<div class="guru-browser-bar"><a class="guru-browser-btn guru-browser-btn-1"></a>
						<div class="guru-browser-btn guru-browser-btn-2"></div><a
							class="guru-browser-btn guru-browser-btn-3"></a>
					</div>
					<div class="guru-browser-browser p-0" v-if="!previewLoading && previewDetail">
						<iframe :src="fakeStoreLink+'/review/'+previewDetail.data.trackId" frameborder="0"
							id="iframeVal" scrolling="yes"></iframe>
					</div>
					<div class="guru-browser-browser text-center p-5" v-else-if="!previewLoading">
						<h4>No application found to preview</h4>
						<p class="text-muted">Enter the app store id and then click the preview button.</p>
					</div>
					<div class="guru-browser-browser text-center p-5" v-else-if="previewLoading">
						<h4>Loading</h4>
					</div>
				</div>

			</b-card>
		</b-col>
	</b-row>
</template>

<script>

import vSelect from 'vue-select'
import {
	ValidationProvider,
	ValidationObserver
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import useCampaignApi from '@/composables/useCampaignApi'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import {
	BCard,
	BRow,
	BCol,
	BForm,
	BButton,
	BFormGroup,
	BFormInput,
	BFormInvalidFeedback,
	BFormCheckbox,
	BFormFile,
	BBadge,
	BMedia,
	BAvatar,
	BFormRating,
	BImg,
	BProgress,
	BProgressBar
} from 'bootstrap-vue'
import {
	required
} from '@validations'
import { mapState } from 'vuex'

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BRow,
		BCard,
		BCol,
		BFormGroup,
		BForm,
		BButton,
		BFormInput,
		vSelect,
		BFormInvalidFeedback,
		ToastificationContent,
		BFormCheckbox,
		BFormFile,
		BBadge,
		BMedia,
		BAvatar,
		BFormRating,
		BImg,
		flatPickr,
		BProgress,
		BProgressBar
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			campaignData: {
				app_id: '',
				file: null,
				type: '',
				name: '',
				organization_id:'',
				ad_account_id:'',
				headline:'',
				start_time:moment().add(1,'days').format('YYYY-MM-DD 00:00'),
				end_time:moment().add(3,'days').format('YYYY-MM-DD 00:00'),
			},
			fakeStoreLink: process.env.VUE_APP_FAKE_STORE_LINK,
			uploadLoad: false,
			required,

		}
	},
	methods: {
		formSubmitted(e) {
			this.uploadLoad = true;
			const formData = new FormData();
			this.campaignData.start_time = moment(this.campaignData.start_time).add(3,'hours').format('YYYY-MM-DD HH:mm')
			this.campaignData.end_time = moment(this.campaignData.end_time).add(3,'hours').format('YYYY-MM-DD HH:mm')
			formData.append('app_id', this.campaignData.app_id)
			formData.append('file', this.campaignData.file)
			formData.append('type', this.campaignData.type)
			formData.append('name', this.campaignData.name)
			formData.append('organization_id', this.campaignData.organization_id)
			formData.append('ad_account_id', this.campaignData.ad_account_id)
			formData.append('headline', this.campaignData.headline)
			formData.append('start_time', this.campaignData.start_time)
			formData.append('end_time', this.campaignData.end_time)

			this.$store.dispatch('campaignStore/addCampaign', formData)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Successfully added!',
							icon: 'CheckIcon',
							variant: 'success',
						},
					})
					this.uploadLoad = false;
					router.push({
						name: 'campaign-detail',
						params: {
							campaign: response.data.data.id
						}
					})
				}).catch((errors) => {
					this.uploadLoad = false;
					console.log(errors.response)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Please check form',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
							text: errors.response ? errors.response.data.message : ''
						},
					})
				})
		},
		validationFormControl() {
			return new Promise((resolve, reject) => {
				this.$refs.campaignForm.validate().then(success => {
					if (success) {
						resolve(true)
					} else {
						reject()
					}
				})
			})
		},
		handleFileObject(event) {
			this.campaignData.file = event.target.files[0];
		},
		changeEndTime(event){
			this.campaignData.end_time = moment(event).add(2,'days').format('YYYY-MM-DD 00:00')
		}

	},
	setup() {

		const {
			previewAppstore,
			previewDetail,
			previewLoading,
			reviewOk,
			fetchOrganizations,
			organizationOptions,
			fetchAdAccounts,
			addAccountsOptions,
			organizationAdAccounts
		} = useCampaignApi()

		return {
			previewAppstore,
			previewDetail,
			previewLoading,
			reviewOk,
			fetchOrganizations,
			organizationOptions,
			fetchAdAccounts,
			addAccountsOptions,
			organizationAdAccounts
		}
	},
	mounted(){
		this.fetchOrganizations()
	},
	computed: {
    	...mapState('campaignStore', ['uploadProgress'])
  	}

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.previewButton{
      border: 1px solid #d2d2d2!important;
}
.guru-browser {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.guru-browser-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  background: #edebed;
  border-radius: 5px 5px 0 0;
}
@media (max-width: 767px) {
  .guru-browser-bar {
    border-radius: 15px 15px 0 0;
  }
}
.guru-browser-btn {
  position: relative;
}
.guru-browser-btn-1 {
  left: 15px;
  background: #fc615d;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 14px;
}
@media (max-width: 767px) {
  .guru-browser-btn-1 {
    background: #BDBDBD;
    width: 36px;
    border-radius: 100px;
  }
}
.guru-browser-btn-2 {
  left: 32px;
  background: #fdbd41;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 14px;
}
@media (max-width: 767px) {
  .guru-browser-btn-2 {
    display: none;
  }
}
.guru-browser-btn-3 {
  left: 49px;
  background: #34c84a;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 14px;
}
@media (max-width: 767px) {
  .guru-browser-btn-3 {
    display: none;
  }
}
.guru-browser .guru-browser-browser {
  width: 100%;
  height: 70vh;
}
.guru-browser .guru-browser-browser #iframeVal {
  width: 100%;
  height: 100%;
  border-radius: 0 0 5px 5px;
}

#iframeVal::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

#iframeVal::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

#iframeVal::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}

</style>